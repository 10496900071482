import "bootstrap/dist/css/bootstrap.min.css"
import ReactMarkdown from "react-markdown"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import React from "react"

const Headlined = data => {
  const styles = {
    "background-image":
      "linear-gradient( rgba(92, 184, 92, 0.85),  rgba(92, 184, 92, 0.85)), url('" +
      data.data.home_image.publicURL +
      "')",
    display: "flex",
    "align-items": "center",
  }
  const lightSeparator = {
    "border-top": "1px dotted whitesmoke",
  }
  return (
    <Jumbotron style={styles} px={0} m={0} className="low-padding">
      <Container className="align-self-center" px={0}>
        <h1>{data.data.title}</h1>
        <hr className="my-4" style={lightSeparator}></hr>
        <ReactMarkdown renderers={{ link: ColoredLink }}>
          {data.data.content}
        </ReactMarkdown>
      </Container>
    </Jumbotron>
  )
}

function ColoredLink(props) {
  return <a {...props} style={{ color: "white" }} />
}

export default Headlined
