import "bootstrap/dist/css/bootstrap.min.css"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Button from "react-bootstrap/Button"
import React from "react"
import "./main-section.css"

const MainSection = data => {
  const styles = {
    "background-image":
      "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('" +
      data.data.home_image.publicURL +
      "')",
    display: "flex",
    "align-items": "center",
  }
  const lightSeparator = {
    "border-top": "1px dotted whitesmoke",
  }
  return (
    <Jumbotron style={styles} px={0} m={0} className="main-jumbotron">
      <Container className="align-self-center">
        <h1 class="display-4">{data.data.title}</h1>
        <p class="lead">{data.data.subtitle}</p>
        <hr className="my-4" style={lightSeparator}></hr>
        <p>{data.data.content}</p>
        <p>
          <a class="btn btn-lg main-cta" href="/page/donate-now" role="button">
            {data.data.ctaText}
          </a>
        </p>
      </Container>
    </Jumbotron>
  )
}
export default MainSection
