import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import Container from "react-bootstrap/Container"
import Jumbotron from "react-bootstrap/Jumbotron"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {
  const styles = {
    color: "#f8f9fa",
    background: "#393939",
    display: "flex",
    "align-items": "center",
    "padding-top": "20px",
    "padding-bottom": "20px",
  }
  const whiteColor = {
    color: "#f8f9fa",
  }
  const lightSeparator = {
    "border-top": "1px dotted #f8f9fa",
  }
  const contactsData = useStaticQuery(graphql`
    {
      allStrapiContact {
        nodes {
          title
          content
        }
      }
    }
  `)
  return (
    <Jumbotron style={styles} className="low-padding">
      <Container className="align-self-center ">
        <h1 className="text-center">Reach Us</h1>
        <hr className="my-4" style={lightSeparator}></hr>
        <Row>
          {contactsData.allStrapiContact.nodes.map(contact => {
            return (
              <Col xs={12} md={4} style={whiteColor}>
                <h6>{contact.title}</h6>
                <ReactMarkdown renderers={{ link: ColoredLink }}>
                  {contact.content}
                </ReactMarkdown>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Jumbotron>
  )
}
function ColoredLink(props) {
  return <a {...props} style={{ color: "white" }} />
}

export default Contact
