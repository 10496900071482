import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import ReactMarkdown from "react-markdown"
import Button from "react-bootstrap/Button"
import Jumbotron from "react-bootstrap/Jumbotron"
import Modal from "react-bootstrap/Modal"
import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link, useStaticQuery, graphql } from "gatsby"
const News = () => {
  const [show, setShow] = useState(false)
  const [showArticle, setShowArticle] = useState({ title: "", content: "" })
  const styles = {
    color: "#393939",
    background: "#f8f9fa",
    display: "flex",
    "align-items": "center",
  }
  const alignCards = { "align-items": "center" }
  const modalStyle = { overflow: "visible" }
  const darkSeparator = {
    "border-top": "1px dotted #393939",
  }
  const newsData = useStaticQuery(graphql`
    {
      allStrapiNotification {
        nodes {
          title
          content
        }
      }
    }
  `)
  const handleReadMore = article => {
    setShowArticle({ title: article.title, content: article.content })
    handleShow()
  }
  const marginBottom = { "margin-bottom": "5px" }
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  return (
    <Jumbotron style={styles} className="low-padding">
      <Container className="align-self-center">
        <h1 className="text-center">News</h1>
        <hr className="my-4" style={darkSeparator}></hr>
        <Row>
          {newsData.allStrapiNotification.nodes.map(article => {
            return (
              <Col xs={12} md={4} style={marginBottom}>
                <Card className="h-100">
                  <Card.Body>
                    <Card.Title>{article.title}</Card.Title>
                    <Card.Link
                      href="#"
                      onClick={() => {
                        handleReadMore(article)
                      }}
                    >
                      Read More
                    </Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose} style={modalStyle}>
        <Modal.Header closeButton>
          <Modal.Title>{showArticle.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactMarkdown>{showArticle.content}</ReactMarkdown>
        </Modal.Body>
      </Modal>
    </Jumbotron>
  )
}

export default News
