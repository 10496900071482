import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Layout from "../components/layout"
import MainSection from "../components/main-section"
import Headlined from "../components/headlined"
import Partitioned from "../components/partitioned"
import SEO from "../components/seo"
import News from "../components/news"
import Contact from "../components/contact"
const styles = {
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiHomeSection(sort: { fields: [id] }) {
        edges {
          node {
            id
            title
            subtitle
            ctaText
            type
            link
            content
            home_image {
              publicURL
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="SRMAB" />
      {data.allStrapiHomeSection.edges.map(homeSection => {
        if (homeSection.node.type === "main") {
          return (
            <Container
              fluid
              style={styles.noPadding}
              id={homeSection.node.link}
              my={0}
            >
              <Row fluid style={styles.noPadding} my={0}>
                <Col sm={12} px={0} my={0} style={styles.noPadding}>
                  <MainSection data={homeSection.node}></MainSection>
                </Col>
              </Row>
            </Container>
          )
        } else if (homeSection.node.type === "headlined") {
          return (
            <Container
              fluid
              style={styles.noPadding}
              id={homeSection.node.link}
            >
              <Row fluid style={styles.noPadding}>
                <Col sm={12} px={0} style={styles.noPadding}>
                  <Headlined data={homeSection.node}></Headlined>
                </Col>
              </Row>
            </Container>
          )
        } else if (homeSection.node.type === "partitioned") {
          return (
            <Container
              fluid
              style={styles.noPadding}
              id={homeSection.node.link}
            >
              <Row fluid style={styles.noPadding}>
                <Col sm={12} px={0} style={styles.noPadding}>
                  <Partitioned data={homeSection.node}></Partitioned>
                </Col>
              </Row>
            </Container>
          )
        }
      })}
      <Container fluid style={styles.noPadding} id="news">
        <Row fluid style={styles.noPadding}>
          <Col sm={12} px={0} style={styles.noPadding}>
            <News></News>
          </Col>
        </Row>
      </Container>
      <Container fluid style={styles.noPadding} id="reach-us">
        <Row fluid style={styles.noPadding}>
          <Col sm={12} px={0} style={styles.noPadding}>
            <Contact></Contact>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
