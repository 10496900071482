import "bootstrap/dist/css/bootstrap.min.css"
import ReactMarkdown from "react-markdown"
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import React from "react"

const Partitioned = data => {
  const styles = {
    color: "#454545 ",
    display: "flex",
    "align-items": "center",
  }
  const darkSeperator = {
    margin: "0px",
    "border-top": "1px dotted darkgray",
  }
  const image = {
    "max-width": "100%",
  }
  return (
    <Jumbotron className="low-padding">
      <Container className="align-self-center">
        <Row>
          <Col xs={12} md={6}>
            <img src={data.data.home_image.publicURL} style={image}></img>
          </Col>
          <Col xs={12} md={6} style={styles}>
            <Container className="align-self-center">
              <Row>
                <Col xs={12}>
                  <h1>{data.data.title}</h1>
                </Col>
              </Row>
              <hr style={darkSeperator}></hr>
              <Row>
                <Col xs={12}>
                  <ReactMarkdown>{data.data.content}</ReactMarkdown>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Jumbotron>
  )
}
export default Partitioned
